<template>
  <v-layout wrap justify-center>
    <MainPhoto/>
    <IconsSheet/>
    <GreySheet/>
    <ContactSheet/>
    <v-col cols="12" class="my-6"></v-col>
  </v-layout>
</template>

<script>
import GreySheet from '@/components/GreySheet.vue';
import MainPhoto from '@/components/MainPhoto.vue';
import IconsSheet from "@/components/IconsSheet.vue";
import ContactSheet from "@/components/ContactSheet.vue";
  export default {
 

    components: {MainPhoto, IconsSheet,GreySheet,ContactSheet,},
  }
</script>


<style>
.jebactebreakpointy {
  display: none !important;
}

@media (min-width: 1930px) {
  .jebactebreakpointy {
    display: block !important;
  }
}
</style>