<template>
<div>
   <!--MD-ONLY -->
   <v-app-bar absolute class="ml-7 mr-7 mt-7 hidden-lg-and-up hidden-sm-and-down"  height="200px" color="rgba(255,255,255,0.9)">
    <v-toolbar-title >
      <v-card flat href="/" color="transparent">
    <v-img src="@/assets/logo.png"  height="70px" contain></v-img>
 </v-card>
  </v-toolbar-title>

  <v-spacer></v-spacer>

  <v-btn-group class="mr-7">
    <v-btn  class="mr-4" href="/our-story" depressed color="transparent" style="text-transform: none">
      Our story
    </v-btn>
    <v-btn  class="mr-4" href="/capabilities" depressed color="transparent" style="text-transform: none">
      Capabilities
    </v-btn>
    <v-btn  class="mr-4" href="/results" depressed color="transparent" style="text-transform: none">
      Results
    </v-btn>
    
    <v-btn href="/contact" depressed color="#B71C50" dark style="text-transform: none">
      Let's talk
    </v-btn>
  </v-btn-group>
</v-app-bar> 

<!--Desktop-->
<v-app-bar absolute class="ml-7 mr-7 mt-7 hidden-md-and-down px-10"  height="200px" color="rgba(255,255,255,0.9)">
    <v-toolbar-title>
   <v-card flat href="/" color="transparent">
    <v-img src="@/assets/logo.png"   height="80px" contain></v-img>
   </v-card>
 
  </v-toolbar-title>

  <v-spacer></v-spacer>

  <v-btn-group class="mr-7">
    <v-btn x-large  class="mr-4" depressed color="transparent"  href="/our-story" style="text-transform: none">
        <span style="font-size: 1.2em">  Our story</span>
    </v-btn>
    <v-btn x-large  href="/capabilities"  class="mr-4" depressed color="transparent" style="text-transform: none">
        <span style="font-size: 1.2em">   Capabilities</span>
    </v-btn>
    <v-btn  x-large  href="/results" class="mr-4" depressed color="transparent" style="text-transform: none">
        <span style="font-size: 1.2em">   Results</span>
    </v-btn>

    <v-btn x-large  href="/contact" depressed color="#B71C50" dark style="text-transform: none">
        <span style="font-size: 1.2em"> Let's talk</span>
    </v-btn>
  </v-btn-group>
</v-app-bar> 

<!--Mobile -->
<v-app-bar fixed class="hidden-md-and-up ma-6 pa-0" color="rgba(255,255,255,0.9)">
  <v-card flat href="/" color="transparent">
    <img src="@/assets/logo.png" height="34px">
    </v-card>
     <v-spacer></v-spacer>

     <v-menu offset-y max-width="300" min-width="200" >
  <template v-slot:activator="{ on }">
    <v-btn small fab color="transparent" depressed v-on="on"><v-icon color="#B71C50">mdi-menu</v-icon></v-btn>
  </template>
  <v-list style="background-color: #B71C50;">
    <v-list-item v-for="(item, index) in items" :key="index" :href="item.href">
      <v-list-item-title style="color: white">{{ item.title }}</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>

    </v-app-bar> 
</div> 
</template>

<script>
export default {
    data() {
  return {
    items: [
  { title: 'Our story', href: '/our-story' },
  { title: 'Capabilities', href: '/capabilities' },
  { title: 'Results', href: '/results' },
  { title: 'Lets talk', href: '/contact' }
]
  }
}
}
</script>


