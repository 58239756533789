<template>
   <v-col class="pa-0" cols="12">

       <!--Desktop View-->
      <v-sheet class="hidden-sm-and-down" color="#B71C50" height="400px">
          <v-layout wrap justify-center align-center style="height: 100%">
              <v-col style="text-align: center" cols="2" class="mx-4">
                  <v-img class="mb-4" height="170px" contain src="@/assets/growth.png"></v-img>
                  <span style="color: white; font-size: 1.4em" class="font-weight-medium">growth</span>
              </v-col>
              <v-col style="text-align: center" cols="2" class="mx-4">
                  <v-img class="mb-4" height="170px" contain src="@/assets/consulting.png"></v-img>
                  <span style="color: white; font-size: 1.4em" class="font-weight-medium">consulting</span>
              </v-col>
              <v-col style="text-align: center" cols="2" class="mx-4">
                  <v-img class="mb-4" height="170px" contain src="@/assets/focus.png"></v-img>
                  <span style="color: white; font-size: 1.4em" class="font-weight-medium">focus</span>
              </v-col>
              <v-col style="text-align: center" cols="2" class="mx-4">
                  <v-img class="mb-4" height="170px" contain src="@/assets/research.png"></v-img>
                  <span style="color: white; font-size: 1.4em" class="font-weight-medium">research</span>
              </v-col>
              
          </v-layout>
      </v-sheet>

      <!--Tablet View-->
      <v-sheet class="hidden-md-and-up hidden-xs-only py-10" color="#B71C50" height="500px">
          <v-layout wrap justify-center align-center style="height: 100%">
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/growth.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">growth</span>
              </v-col>
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/consulting.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">consulting</span>
              </v-col>
           
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/focus.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">focus</span>
              </v-col>
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/research.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">research</span>
              </v-col>
              
          </v-layout>
      </v-sheet>

        <!--Mobile View-->
        <v-sheet class="hidden-sm-and-up py-10" color="#B71C50">
          <v-layout wrap justify-center align-center style="height: 100%">
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/growth.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">growth</span>
              </v-col>
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/consulting.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">consulting</span>
              </v-col>
           
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/focus.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">focus</span>
              </v-col>
              <v-col style="text-align: center" cols="10" sm="5" class="mx-1">
                  <v-img class="mb-4" height="100px" contain src="@/assets/research.png"></v-img>
                  <span style="color: white; font-size: 0.9em" class="font-weight-medium">research</span>
              </v-col>
              
          </v-layout>
      </v-sheet>
    </v-col>
</template>

<script>
export default {

}
</script>

<style>

</style>