<template>
    <v-col cols="12" class="pa-0">
        <!--Desktop View-->
        <v-sheet class="hidden-sm-and-down" color="#222833" height="500px" style="overflow: hidden;">
            <v-layout style="height: 100%;" align-center>
                <v-col cols="7" style="height: 100%">
                    <v-layout style="height: 100%" justify-center align-center>
                        <div>
                            <v-layout column>
                                <span style="color: white; font-size: 1.5em">Partnering for progress</span>
                                <span style="color: white; font-size: 3.5em;" class="font-weight-black">Elevate your</span>
                                <span style="color: white; font-size: 3.5em; line-height: 0.6em;"
                                    class="font-weight-black">Business with us</span>
                            </v-layout>
                        </div>
                    </v-layout>
                </v-col>
                <v-col cols="5" class="pa-0">
                    <v-img src="@/assets/greyBuilding.png" width="100%" height="500px"
                        style="position: relative;  overflow: visible;"></v-img>
                </v-col>
            </v-layout>

        </v-sheet>
        <!--Tablet View-->

        <v-col cols="12" class="hidden-md-and-up hidden-xs-only pa-0">
            <v-parallax src="@/assets/greyBuilding.png"  height="300"
                style="position: relative;  overflow: visible;">
              <v-layout style="height: 100%" align-center justify-center>
            <v-sheet style="opacity: 0.8;" color="#222833" height="200px" class="px-10">
                <v-layout column class="mt-3">
                                <span style="color: white; font-size: 1.5em">Partnering for progress</span>
                                <span style="color: white; font-size: 3.5em;" class="font-weight-black">Elevate your</span>
                                <span style="color: white; font-size: 3.5em; line-height: 0.6em;"
                                    class="font-weight-black">Business with us</span>
                            </v-layout>
            </v-sheet>
            </v-layout>
            </v-parallax>
        </v-col>
<!--Mobile View-->
        <v-col cols="12" class="hidden-sm-and-up pa-0">
            <v-parallax src="@/assets/greyBuilding.png"  height="200"
                style="position: relative;  overflow: visible;">
              <v-layout style="height: 100%" align-center justify-center>
            <v-sheet style="opacity: 0.8;" color="#222833" height="125px" class="px-10">
                <v-layout column class="mt-5">
                                <span style="color: white; font-size: 0.75em">Partnering for progress</span>
                                <span style="color: white; font-size: 1.75em;" class="font-weight-black">Elevate your</span>
                                <span style="color: white; font-size: 1.75em; line-height: 0.6em;"
                                    class="font-weight-black">Business with us</span>
                            </v-layout>
            </v-sheet>
            </v-layout>
            </v-parallax>
        </v-col>



    </v-col>
</template>

<script>
export default {

}
</script>

<style></style>