<template>
  <v-app>
  <AppBar/>

    <v-main>
      <router-view/>
    </v-main>
    <FooterBottom/>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import FooterBottom from "@/components/FooterBottom.vue";
export default {
  name: 'App',
components:{AppBar, FooterBottom,},
  data: () => ({
    //
  }),
};
</script>
