<template>
    <!-- <v-img src="@/assets/contactBackground.png"> -->
    <!--Desktop View-->
<v-layout>
    <v-col class="jebactebreakpointy" cols="2"> </v-col>
    <v-layout justify-center>
        <v-col xl="9" lg="9" md="10" sm="10" xs="11" cols="11">
            <v-sheet style="border-radius: 25px" class="pa-3" color="white">
                <v-layout class="hidden-xs-only" wrap>
                    <v-col cols="12">
                        <span style="font-size: 1.5em;" class="font-weight-bold">Get in touch with us</span>
                    </v-col>
                    <v-col xl="7" lg="7" md="7" sm="12" cols="12">
                        <v-layout column align-start>
                            <span>Thank you for considering our services! Please fill out the form below to get in touch
                                with us. We are excited to learn more about your business and how we can help you achieve
                                your goals.</span>
                            <v-card class="mt-8 pa-4" style="border-radius: 25px" width="100%">
                                <v-layout column>
                                    <v-form v-model="valid" ref="form" @submit.prevent="SendMessage">

                                        <v-layout>
                                            <v-text-field dense height="10px" :rules="[rules.required]" v-model="name"
                                                name="name" class="px-3" filled rounded label="Name">
                                            </v-text-field>
                                            <v-text-field dense v-model="email" :rules="[rules.required, rules.email]"
                                                name="email" class="px-3" filled rounded
                                                label="E-mail Address"></v-text-field>
                                        </v-layout>

                                        <v-layout>
                                            <v-text-field dense v-model="phone" :rules="[rules.required]" name="phone"
                                                class="px-3" filled rounded label="Phone Number"></v-text-field>
                                            <v-text-field dense v-model="company" :rules="[rules.required]" name="company"
                                                class="px-3" filled rounded label="Company Name"></v-text-field>
                                        </v-layout>
                                        <v-select dense v-model="services" :rules="[rules.required]" name="services"
                                            class="px-3" filled rounded label="What services are you interested in?"
                                            :items="serviceOptions"></v-select>


                                        <v-text-field dense v-model="objectives" :rules="[rules.required]" name="objectives"
                                            class="px-3" filled rounded
                                            label="What are your current business challenges and objectives?"></v-text-field>

                                        <v-text-field dense v-model="recommendation" :rules="[rules.required]"
                                            name="recommendation" class="px-3" filled rounded
                                            label="How did you hear about us?"></v-text-field>
                                        <v-textarea dense v-model="comments" name="comments" class="px-3" filled rounded
                                            label="Additional Comments/Questions:"></v-textarea>


                                        <v-layout justify-end>
                                            <v-btn type="submit" :disabled="!valid" large rounded style="color: white"
                                                color="#1B1E25">SEND</v-btn>
                                        </v-layout>
                                    </v-form>
                                    <v-dialog v-model="dialog" max-width="800" persistent>
                                        <v-card class="pa-3">
                                            <v-card-title class="headline">Message sent!</v-card-title>
                                            <v-card-text style="font-size: 1.2em"> Thank you for taking the time to fill out
                                                this form. We will be in touch with you shortly to discuss how we can help
                                                your business succeed.</v-card-text>
                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn dark rounded class="mr-2" color="#1B1E25"
                                                    @click="dialog = false">Close</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-layout>
                            </v-card>
                        </v-layout>

                    </v-col>
                    <v-col xl="5" lg="5" md="5" sm="12" cols="12">

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.748912583686!2d17.94568847782926!3d59.40390150504865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9eed4bacaaa1%3A0x6a315deeee79d68!2sKistag%C3%A5ngen%2020b%2C%20164%2040%20Kista%2C%20Szwecja!5e0!3m2!1spl!2spl!4v1713179150919!5m2!1spl!2spl"
                            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe> </v-col>

                </v-layout>
                <v-layout class="hidden-md-and-up" wrap>
                    <v-col cols="12">
                        <span style="font-size: 1.5em;" class="font-weight-bold">Get in touch with us</span>
                    </v-col>
                    <v-col xl="7" lg="7" md="7" sm="12" cols="12">
                        <v-layout column align-start>
                            <span>Thank you for considering our services! Please fill out the form below to get in touch
                                with us. We are excited to learn more about your business and how we can help you achieve
                                your goals.</span>
                            <v-card class="mt-8 pa-4" style="border-radius: 25px" width="100%">
                                <v-layout column>
                                    <v-form v-model="valid" ref="form" @submit.prevent="SendMessage">


                                        <v-text-field dense height="10px" :rules="[rules.required]" v-model="name"
                                            name="name" class="px-3" filled rounded label="Name">
                                        </v-text-field>
                                        <v-text-field dense v-model="email" :rules="[rules.required, rules.email]"
                                            name="email" class="px-3" filled rounded label="E-mail Address"></v-text-field>



                                        <v-text-field dense v-model="phone" :rules="[rules.required]" name="phone"
                                            class="px-3" filled rounded label="Phone Number"></v-text-field>
                                        <v-text-field dense v-model="company" :rules="[rules.required]" name="company"
                                            class="px-3" filled rounded label="Company Name"></v-text-field>

                                        <v-select dense v-model="services" :rules="[rules.required]" name="services"
                                            class="px-3" filled rounded label="What services are you interested in?"
                                            :items="serviceOptions"></v-select>


                                        <v-text-field dense v-model="objectives" :rules="[rules.required]" name="objectives"
                                            class="px-3" filled rounded
                                            label="What are your current business challenges and objectives?"></v-text-field>

                                        <v-text-field dense v-model="recommendation" :rules="[rules.required]"
                                            name="recommendation" class="px-3" filled rounded
                                            label="How did you hear about us?"></v-text-field>
                                        <v-textarea dense v-model="comments" name="comments" class="px-3" filled rounded
                                            label="Additional Comments/Questions:"></v-textarea>


                                        <v-layout justify-end>
                                            <v-btn type="submit" :disabled="!valid" large rounded style="color: white"
                                                color="#1B1E25">SEND</v-btn>
                                        </v-layout>
                                    </v-form>
                                    <v-dialog v-model="dialog" max-width="800" persistent>
                                        <v-card class="pa-3">
                                            <v-card-title class="headline">Message sent!</v-card-title>
                                            <v-card-text style="font-size: 1.2em"> Thank you for taking the time to fill out
                                                this form. We will be in touch with you shortly to discuss how we can help
                                                your business succeed.</v-card-text>
                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn dark rounded class="mr-2" color="#1B1E25"
                                                    @click="dialog = false">Close</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-layout>
                            </v-card>
                        </v-layout>

                    </v-col>
                    <v-col xl="5" lg="5" md="5" sm="12" cols="12" class="my-6">

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.748912583686!2d17.94568847782926!3d59.40390150504865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9eed4bacaaa1%3A0x6a315deeee79d68!2sKistag%C3%A5ngen%2020b%2C%20164%2040%20Kista%2C%20Szwecja!5e0!3m2!1spl!2spl!4v1713179150919!5m2!1spl!2spl"
                            width="100%" height="300px" style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe> </v-col>

                </v-layout>
            </v-sheet>
        </v-col>
    </v-layout>
    <v-col class="jebactebreakpointy" cols="2"> </v-col>
</v-layout>
    <!-- </v-img> -->
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
    methods: {
        SendMessage: function (e) {
            let self = this
            emailjs
                .sendForm("service_wir6vwc", "template_44gj16s", e.target, '9NhJnJFF56brJs0iq')
                .then((result) => {
                    console.log("SUCCESS!", result.status, result.text);
                    console.log(e.target)
                    self.dialog = true;
                    self.$refs.form.reset();
                },
                    error => {
                        console.log("FAILED...", error);
                    }
                );
        },
    },
    data() {
        return {
            serviceOptions: [
                'Business Management',
                'Financial Management',
                'Sales and Marketing',
                'Strategic Planning',
                'Leadership Development',
                'Other'
            ],
            name: "",
            phone: "",
            email: "",
            company: "",
            services: "",
            objectives: "",
            recommendation: "",
            comments: "",
            dialog: false,
            valid: false,

            rules: {
                required: value => !!value || "This field is required",
                // counter: value => value.length < 50 || "Maksymalnie 50 znaków!!!",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "unvalid e-maill address";
                },
                //  message: value => value.length < 250 || "Maximum of 250 characters",
            },
        }

    }
};
</script>

<style></style>



