<template>
    <v-img src="@/assets/mainPhoto.jpg" height="100vh" style="transform: scaleX(-1);">
     
        <!--*DESKTOP VIEW-->
        <v-layout class="hidden-sm-and-down" justify-center align-center style="height: 100%; transform: scaleX(-1);">
            <v-col cols="1" style="height: 100%"></v-col>
            <v-col cols="10" class="mt-12" style="height: 100%">
                <v-layout class="mt-12" justify-center align-start column style="height: 100%; ">
                  <v-sheet class="pa-7" color="rgba(255,255,255,0.8)">
                    <v-layout column>
                    <span style="font-size: 5.3em; color: black; line-height: 100px;" class="font-weight-bold">Grow
                        your </span>
                    <span style="font-size: 5.3em; color: black; line-height: 100px;" class="font-weight-bold">business
                        right.</span>
                    <span style="font-size: 2.2em; color: black; line-height: 110px;" class="font-weight-light">Group of passionate professionals with combined</span>
                    <span style="font-size: 2.2em; color: black; line-height: 1px;" class="font-weight-light"> personal
                        experience of almost 150 years.</span>

                    <v-layout class="mt-12" style="max-height: 100px; ">
                        <v-btn @click="$vuetify.goTo('#contact1')" large href="/contact" depressed rounded outlined color="#B71C50"><span style="color: black">GET IN
                                TOUCH</span></v-btn>
                        <v-btn @click="$vuetify.goTo('#about1')" href="/our-story" class="ml-5" large depressed rounded color="#B71C50"><span style="color: white">READ
                                MORE</span></v-btn>
                    </v-layout>
                  </v-layout>
                  </v-sheet>

                </v-layout>
            </v-col>
            <v-col cols="1" style="height: 100%"></v-col>


        </v-layout>

        <!--*TABLET VIEW-->
        <v-layout class="hidden-md-and-up hidden-xs-only" justify-center align-center style="height: 100%; transform: scaleX(-1);">
            <v-col cols="1" style="height: 100%"></v-col>
            <v-col cols="10" style="height: 100%">
                <v-layout justify-center align-start column style="height: 100%">
                    <v-sheet class="pa-7" color="rgba(255,255,255,0.8)">
                    <v-layout column>
                    <span style="font-size: 3.5em; color: black; line-height: 70px;" class="font-weight-bold">Grow your</span>
                    <span style="font-size: 3.5em; color: black; line-height: 70px;" class="font-weight-bold">business right.</span>
                    <span style="font-size: 1.37em; color: black; line-height: 70px;" class="font-weight-light">Group of
                        passionate professionals with combined</span>
                    <span style="font-size: 1.37em; color: black; line-height: 1px;" class="font-weight-light">personal
                        experience of almost 150 years.</span>

                    <v-layout class="mt-12" style="max-height: 100px;">
                        <v-btn @click="$vuetify.goTo('#contact1')" href="/contact" large depressed rounded outlined color="#B71C50"><span style="color: black">GET IN
                                TOUCH</span></v-btn>
                        <v-btn @click="$vuetify.goTo('#about1')" href="/our-story" class="ml-5" large depressed rounded color="#B71C50"><span style="color: white">READ
                                MORE</span></v-btn>
                    </v-layout>
                </v-layout>
                  </v-sheet>
                </v-layout>
            </v-col>
            <v-col cols="1" style="height: 100%"></v-col>


        </v-layout>

        <!--*MOBILE VIEW-->
        <v-layout class="hidden-sm-and-up" justify-center align-center style="height: 100%; transform: scaleX(-1);">
            <v-col cols="1" style="height: 100%"></v-col>
            <v-col cols="10" style="height: 100%">
                
                <v-layout justify-center align-start column style="height: 100%;">
                    <v-sheet class="pa-7" color="rgba(255,255,255,0.8)">
                    <v-layout column>
                    <span style="font-size: 2em; color: black; line-height: 40px;"
                        class="font-weight-bold mt-10">Grow your</span>
                    <span style="font-size: 2em; color: black; line-height: 40px;" class="font-weight-bold">business right.</span>
                    <span style="font-size: 1em; color: black; line-height: 20px;" class="font-weight-light mt-7">Group
                        of
                        passionate professionals </span>
                    <span style="font-size: 1em; color: black; line-height: 30px;" class="font-weight-light">
                        with combined personal experience </span>
                    <span style="font-size: 1em; color: black; line-height: 20px;" class="font-weight-light">
                        of almost 150 years.</span>

                    <v-layout class="mt-8" style="max-height: 100px;">
                        <v-btn @click="$vuetify.goTo('#contact1')" href="/contact" small depressed rounded outlined color="#B71C50"><span style="color: black">GET IN
                                TOUCH</span></v-btn>
                        <v-btn @click="$vuetify.goTo('#about1')" small href="/our-story" class="ml-5" depressed rounded color="#B71C50"><span style="color: white">READ
                                MORE</span></v-btn>
                    </v-layout>
                </v-layout>
                  </v-sheet>
                </v-layout>
            </v-col>
            <v-col cols="1" style="height: 100%"></v-col>


        </v-layout>

    </v-img>
</template>

<script>
export default {

}
</script>

<style></style>