<template>
<v-footer color="#222833" dark height="50px">
    <v-layout justify-center align-center style="height: 100%">
        <span>Demarso Management Kommanditbolag 2023</span>
    </v-layout>
</v-footer>  
</template>

<script>
export default {

}
</script>

<style>

</style>